    <template>
        <div>
            <nav-bar></nav-bar>
            <div class="menuitems-main">
                <div class="menuitems-main-wrapper d-xl-flex">
                    <!-----Layout-1 Start---->
                    <div class="side-menu-tag">
                        <ul id="menulist" role="tablist" aria-orientation="vertical">
                            <li v-for="menu in tagWiseMenu" :key="menu.id" class="mb-0">
                                <button class="nav-link w-100 py-2 px-3 text-start rmvactv 80 side-menu-link"
                                    :id="'menuitem' + menu.id" data-bs-toggle="pill" :data-bs-target="'#item' + menu.id"
                                    type="button" role="tab" :aria-controls="'item' + menu.id" aria-selected="false">{{
                                        menu.tag_name }}</button>
                            </li>
                        </ul>
                    </div>
                    <div v-for="menu in tagWiseMenu" :key="menu.id" class="menulength" :id="'itemmenumain' + menu.id">
                        <div class="lyout-cls d-flex align-items-center flex-grow-1 mt-5"
                            v-for="item in menu.menu_items" :key="item.id">
                            <div class="media media1" data-bs-toggle="modal" data-bs-target="#item-popup"
                                @click="productModal(item)">
                                <div class="item-img itemimg-squre">
                                    <img :src="item.image" />
                                </div>

                                <a v-if="isBrowser"
                                    :href="(pagename == 'itemslist' || pagename == 'qr') ? 'javascript:void(0)' : '/vendor/' + encodeBase64(restaurantID) + '#item' + menuId"
                                    id="element" class="btn btn-default show-modal py-3">
                                    <div class="media-body">
                                        <div class="veg nveg" v-if="item.item_type == '0'"><span></span></div>
                                        <div class="non-veg nveg" v-if="item.item_type == '1'"><span></span></div>
                                        <h5 class="mt-0 me-5 item-name">{{ item.item_name }}</h5>
                                        <p class="mb-0 mt-2 item-descr" v-if="item.item_description">
                                            {{ (item.item_description && item.item_description.length > 80 ?
                                                item.item_description.substring(0, 80) +
                                                ".."
                                                : item.item_description) }}
                                        </p>
                                        <div class="prc-show">
                                            <span class="mt-2 d-inline-block item-price" v-if="item.price != '0'"><span
                                                    v-html="$auth.setting && $auth.setting.currency"
                                                    class="currency"></span>{{
                                                        $formatPrice(item.price) }}</span>
                                            <span class="mt-2 ms-2 d-inline-block item-price strikeout"
                                                v-if="item.mrp != item.price"><span
                                                    v-html="$auth.setting && $auth.setting.currency"
                                                    class="currency"></span>{{ (item.mrp).toFixed(2)
                                                }}</span>
                                        </div>
                                        <!-- <PriceComponent :item="item" /> -->
                                        <div class="tags-list mb-1" v-if="item.item_tags && item.item_tags.length > 0">
                                            <ul>
                                                <li v-for="(tag, i) in item.item_tags" :key='i'
                                                    :style="{ background: tag.background_color + '!important', color: tag.text_color }">
                                                    {{ tag.name }}
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </a>
                            </div>
                            <!--Image with button start----->
                            <div class="img-btn">
                                <div class="pgnme-cls" v-if="pagename != 'qr'">
                                    <div v-if="!closeLabel" class="nw-itm-btn me-2">
                                        <div v-if="item.is_sold_out == '0'" class="sold-de">
                                            <div v-if="item.is_customization >= '1'" class="csm-de">
                                                <button class="add-to-cart btn btn-outline-warning btn-sm"
                                                    data-bs-toggle="modal" data-bs-target="#item-popup"
                                                    @click="productModal(item)" v-if="checkInCart(item.item_id)">{{
                                                        $t('Add')
                                                    }}</button>
                                                <div v-else>
                                                    <div class="quantityInc 1">
                                                        <div class="number d-flex align-items-center">
                                                            <span
                                                                class="minus d-flex justify-content-center align-items-center"
                                                                @click="minusItem(item)">-</span>
                                                            <input type="text"
                                                                :value="cart.reduce((a, c) => (a + (c.item_id == item.item_id ? c.quantity : 0)), 0)"
                                                                readonly />
                                                            <span
                                                                class="plus d-flex justify-content-center align-items-center"
                                                                @click="productModal(item)">+</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class="icus">
                                                <div v-if="item && item.is_item_subscribe == 1 && isPickupDelivery == 1"
                                                    class="d-lg-block">
                                                    <button class="btn" @click="addSubscription(item)">
                                                        <img src="../assets/images/sync.png" alt=""
                                                            class="subscription-icon" />
                                                    </button>
                                                </div>
                                                <button v-if="item.is_sold_out == '0' && checkInCart(item.item_id)"
                                                    data-bs-toggle="modal"
                                                    class="add-to-cart btn btn-outline-warning btn-sm"
                                                    @click="addProductToCart(item)">{{ $t('Add')
                                                    }}</button>
                                                <div v-else>
                                                    <div class="quantityInc 2">
                                                        <div class="number d-flex align-items-center">
                                                            <span
                                                                class="minus d-flex justify-content-center align-items-center"
                                                                @click="minusItem(item)">-</span>
                                                            <input type="text"
                                                                :value="cart.reduce((a, c) => (a + (c.item_id == item.item_id ? c.quantity : 0)), 0)"
                                                                readonly />
                                                            <span
                                                                class="plus d-flex justify-content-center align-items-center"
                                                                @click="plusItem(item)">+</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p v-if="item.is_customization" class="tag-customize tagcust-nw">{{
                                                $t('Customisable') }}</p>
                                            <div v-if="item && item.is_item_subscribe == 1 && item.is_customization && isPickupDelivery == 1"
                                                class="d-lg-block">
                                                <button class="btn" @click="addSubscription(item)">
                                                    <!-- <i class="fa fa-calendar" aria-hidden="true"></i> -->
                                                    <img src="../assets/images/sync.png" alt=""
                                                        class="subscription-icon" />
                                                </button>
                                            </div>
                                        </div>
                                        <div v-else class="sold-bt">
                                            <button v-if="item.is_sold_out != '0'"
                                                class="sold add-to-cart btn btn-outline-warning btn-sm">{{ $t('Sold
                                                Out')}}</button>
                                        </div>
                                    </div>
                                </div>
                                <!--Image with button end----->
                            </div>
                        </div>
                    </div>


                    <!--Alert Modal start-->

                    <AlertModal v-if="isAlert" :DisplayAlertData="AlertData" v-on:modalClose="onModalCloseFun">
                    </AlertModal>
                    <!--Alert Modal End-->
                </div>
            </div>
        </div>
    </template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import AlertModal from '../components/AlertModal.vue';
import NavBar from '../components/NavBar.vue';
import Product from "../components/Product.vue";

export default {
    props: ["item", "menuId", "currencys", "restaurantID", "restaurant_on_off", "pagename", "slug", "itemlayout", "restautantDetails"],
    components: { AlertModal, NavBar, Product },
    data() {
        return {
            imgError: "",
            productId: "",
            noImgClass: '',
            closeLabel: false,
            isAlert: false,
            AlertData: {},
            tempProduct: {},
            tagWiseMenu: [],
            layoutClass: '',
            singleVendorRestaurantID: 0,
            filter: {
                lat: "",
                lng: "",
                city: "",
                state: "",
                is_pickup_delivery: "",
            },
        };
    },
    mounted() {
        //Restaurant close or schedule for
        localStorage.setItem('restaurantID', this.$route.params.restaurant_id)
        this.getTagWiseRestaurantMenus()
        // console.log(this.base64_decode(this.$route.params.restaurant_id), "sdjkkfkj")
        if (this.restaurant_on_off == '0' && this.$auth.setting && this.$auth.setting.is_enable_schedule_order_after_business_hours == 0) {
            this.closeLabel = true;
        } else {
            this.closeLabel = false;
        }

        if (this.itemlayout == '3' || this.itemlayout == '2' || this.itemlayout == '4') {
            this.layoutClass = 'secondlayout';
        }
    },

    computed: {
        ...mapGetters("product", ["cart"]),
        ...mapGetters("order", ["isPickupDelivery"]),
        isBrowser() {
            return typeof window !== 'undefined';
        }
    },

    methods: {
        ...mapActions("product", ["addProductToCart", "removeProductFromCart", "addTOSubscription"]),
        ...mapActions("restaurant", ["getTagWiseRestaurantItem"]),
        ...mapMutations("product", ["decrementProductInventary", "IncrementItemQuantity", "clearCartItems"]),
        ...mapMutations("restaurant", ["getRestaurantBySlug"]),


        getTagWiseRestaurantMenus(restid) {
            this.isItemLoader = true;
            this.resstoId = localStorage.getItem("restaurantID");
            this.filter.lat = ((localStorage.getItem("latitude") != '' && localStorage.getItem("latitude") != null) ? parseFloat(localStorage.getItem("latitude")) : '');
            this.filter.lng = ((localStorage.getItem("longitude") != '' && localStorage.getItem("longitude") != null) ? parseFloat(localStorage.getItem("longitude")) : '');
            console.log(this.filter.lat, "this.filter.lat")
            this.singleVendorRestaurantID = this.base64_decode(localStorage.getItem("restaurantId"));
            this.getTagWiseRestaurantItem({
                vendor_id: this.$auth.getVendorId(),
                is_langauge: this.$store.state.lang.locale,
                skip: 0,
                latitude: this.filter.lat,
                longitude: this.filter.lng,
                restaurant_id: this.singleVendorRestaurantID,
                total_record: 10,
            }).then((data) => {
                this.isItemLoader = false;
                if (data.code == 200) {
                    this.tagWiseMenu = data.Result;
                    console.log(this.tagWiseMenu, "this.tagWiseMenu")
                }
            })
        },


        onModalCloseFun(value) {
            this.isAlert = false;
            if (value == 'yes') {
                this.$store.commit("product/clearCartItems");
                this.addProductCart(this.tempProduct);
            }
        },
        encodeBase64(value) {
            // Ensure window.btoa is available
            return this.isBrowser ? window.btoa(value) : '';
        },

        addProductToCart(product) {
            var checkRestarant = this.cart.find((ele) => (ele.restaurant_id == this.restaurantID));
            if (this.cart.length) {
                if (checkRestarant) {
                    this.addProductCart(product);
                } else {
                    this.AlertData = {
                        displayMessage: this.$t('Your cart contain items from another ') + this.$t(this.$auth && this.$auth.getRestaurantName()) + this.$t(' Do you wish to clear cart and start a new order here?'),
                        isSetClass: 'prdct-modal-cls'
                    };
                    this.isAlert = true;
                    this.tempProduct = product;
                    window.$("#AlertModalPlaced").modal("show");
                    //window.$(this.$refs.AlertModal).modal();
                }
            } else {
                this.addProductCart(product);
            }
        },


        addSubscription(product) {
            localStorage.removeItem('subscriptionModal_data')
            localStorage.removeItem('subscription_end_date')
            localStorage.removeItem('subscribedType')
            localStorage.removeItem('subscription_cart')
            localStorage.setItem('freeCode', JSON.stringify(this.item))
            if (product.item_id) {
                localStorage.setItem('itemId', product.item_id);
            }
            // localStorage.removeItem('subscriptionModal_data')
            // productDetailsModal
            // $("#productDetailsModal").modal("hide");
            let itemTax = 0;
            let savemrp = 0;
            if (product.item_tax != "0") {
                itemTax = product.price - product.price / (1 + product.item_tax / 100);
            }

            if (product.price < product.mrp) {
                savemrp = product.mrp - product.price;
            }

            var addToSubscriptionData = {
                vendor_id: this.$auth.getVendorId(),
                restaurant_id: product.restaurant_id,
                item_id: product.item_id,
                item_name: product.item_name,
                // price: product.price,
                price: product.price,
                quantity: 1,
                itemTotalQuantity: product.quantity,
                selected_type: [],
                selectCustoizeID: [],
                selectCustoizePrice: [],
                item_type: product.item_type,
                item_price: product.price,
                image: product.image,
                saveprice: savemrp,
                item_tax: itemTax,
                mrp: product.mrp,
                packaging_charge: product.item_packaging_charge,
            };

            // addSubscription
            this.addTOSubscription(addToSubscriptionData);
            this.$router.push({ name: "review-subscription" });
        },

        addProductCart(product) {
            let itemTax = 0;
            let savemrp = 0;
            if (product.item_tax != '0') {
                itemTax = (product.price - (product.price / (1 + (product.item_tax / 100))));
            }

            if (product.price < product.mrp) {
                savemrp = product.mrp - product.price;
            }
            var AddToCartItemArray = {
                vendor_id: this.$auth.getVendorId(),
                restaurant_id: product.restaurant_id,
                item_id: product.item_id,
                item_name: product.item_name,
                price: product.price,
                quantity: 1,
                itemTotalQuantity: product.quantity,
                selected_type: [],
                selectCustoizeID: [],
                selectCustoizePrice: [],
                item_type: product.item_type,
                item_price: product.price,
                image: product.image,
                saveprice: savemrp,
                item_tax: itemTax,
                mrp: product.mrp,
                packaging_charge: product.item_packaging_charge
            };
            this.$store.commit("restaurant/getRestaurantBySlug", this.restautantDetails);
            this.$store.dispatch("product/addProductToCart", AddToCartItemArray);
        },

        productModal(item) {
            item.restaurant_on_off = this.restaurant_on_off;
            item.restaurant_id = this.restaurantID;
            this.$emit("openProductDetailModal", item);
        },

        plusItem(product) {
            const cartItemIndex = this.cart.findIndex(item => parseInt(item.item_id) === parseInt(product.item_id));
            var itemTotalQuantity = this.cart[cartItemIndex].quantity;
            let cartItem = {
                'cartItemIndex': cartItemIndex,
                'product': product
            };
            if (itemTotalQuantity < product.quantity) {
                this.$store.commit("product/IncrementItemQuantity", cartItem);
            } else {
                alert(this.$t("Maximum items reached"));
            }
        },

        minusItem(product) {
            const cartItemIndex = this.cart.findIndex(item => parseInt(item.item_id) === parseInt(product.item_id));
            let totalSmCartID = [];
            this.cart.forEach(item => {
                if (item.item_id === product.item_id) {
                    totalSmCartID.push(item.item_id);
                }
            });
            if (totalSmCartID.length > 1) {
                alert(this.$t("Please Remove Item From Cart!"));
            } else {
                var itemQuantity = this.cart[cartItemIndex].quantity;
                if (itemQuantity == '1' || itemQuantity == '0') {
                    this.deleteProduct(cartItemIndex);
                } else {
                    this.$store.commit("product/decrementProductInventary", cartItemIndex);
                }
            }
            return false;
        },

        deleteProduct(index) {
            this.$store.dispatch("product/removeProductFromCart", index)
        },

        checkInCart(itemId) {
            var checkItem = this.cart.find((ele) => (ele.item_id == itemId));
            if (checkItem) {
                return false;
            } else {
                return true;
            }
        },

        onImgError() {
            this.imgError = true;
            this.noImgClass = 'noImgClass';
        },
    },
};
</script>
<style>
.media-itms-theme5 .sold-de {
    /* width: 120px !important; */
    margin: 0;
}

.side-menu-tag {
    padding-bottom: 30px;
    padding-top: 5%;
    flex: 0 0 280px;
    border-right: 1px solid var(--theme-secondary);
    height: 90vh;
}


.subscription-icon {
    width: 25px;
}

.food-title {
    font-size: 12px;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
    color: #949494;
}

.sold-bt button.sold,
.sold-de .csm-de button {
    /* width: 100%; */
    margin-top: 4px !important;
    height: 30px;
}
</style>